<template>
  <div>
    <va-card title="Update Profile">
      <div>
        <form @submit.prevent="updateUser()">
          <va-input
            v-model="first_name"
            type="text"
            pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
            label="First Name"
            required="true"
            placeholder="Enter First Name"/>
          <va-input
            v-model="last_name"
            type="text"
            pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
            label="Last Name"
            placeholder="Enter Last Name"/>
          <va-input
            v-model="user_name"
            type="text"
            label="username"
            :disabled="true"
          />
          <va-input
            v-model="role"
            type="text"
            label="Role"
            :disabled="true"/>
          <va-input
            v-model="password"
            type="password"
            label="Password ( Maximum length is 20 characters )"
            :required="passwordRequiredStatus"
            pattern="[A-Za-z0-9_@!./#&+-^]{8,}$"
            placeholder="Enter Password"
          />
          <va-input
            v-model="mobile_number"
            type="text"
            label="Mobile Number"
            pattern="[6-9]{1}[0-9]{9}"
            maxlength="10"
            required="true"
            placeholder="Enter Mobile Number"/>
          <div class="d-flex justify--center mt-3">
            <va-button type="submit" class="my-0">Update</va-button>
          </div>
        </form>
      </div>
    </va-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'profile',
  beforeCreate () {
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + 'user/' + this.$cookies.get('user-id')).then(response => {
      loader.hide()
      var user_profile = response.body
      this.role = user_profile.role
      this.last_name = user_profile.last_name
      this.first_name = user_profile.first_name
      this.user_name = user_profile.user_name
      this.password = ''
      this.mobile_number = user_profile.mobile_number
      this.user_id = user_profile.user_id
    }, error => {
      if (error && error.body) {
        Vue.notify({ text: error.body, type: 'error'})
      }
      this.getAllUser()
      loader.hide()
    })
  },
  data () {
    return {
      first_name: '',
      last_name: '',
      user_name: '',
      password: '',
      mobile_number: '',
      role:'',
    }
  },
  computed: {
    passwordRequiredStatus(){
      return this.password ? true:false
    },
    checkpassword () {
      if (this.password.length == 0) {
        return true
      } else if (this.password.length >= 8) {
        return true
      } else {
        return false
      }
    },

  },
  methods: {
    getAllUser () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'user/' + this.$cookies.get('user-id')).then(response => {
        loader.hide()
        var user_profile = response.body
        this.role = user_profile.role
        this.first_name = user_profile.first_name
        this.last_name = user_profile.last_name
        this.user_name = user_profile.user_name
        this.password = ''
        this.mobile_number = user_profile.mobile_number
        this.user_id = user_profile.user_id
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    updateUser () {
      var payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        user_name: this.user_name,
        mobile_number: this.mobile_number,
      }
      if (this.password) {
        payload.password = this.password
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + 'user/' + this.user_id, payload).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({text: response.body, type: 'success'})
        }
        this.getAllUser()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error'})
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
